export const GET_ALL_COHORTS = 'GET_ALL_COHORTS';
export const GET_COHORT = 'GET_COHORT';
export const ADD_COHORT = 'ADD_COHORT';
export const DELETE_COHORT = 'DELETE_COHORT';
export const EDIT_COHORT = 'EDIT_COHORT';
export const SAVE_COHORT = 'SAVE_COHORT';
export const DELETE_ALL_COHORTS = 'DELETE_ALL_COHORTS';
export const ENABLE_ALL_COHORTS = 'ENABLE_ALL_COHORTS';
export const DISABLE_ALL_COHORTS = 'DISABLE_ALL_COHORTS';
export const ENABLE_ACCESS_COMPETITION_IN_ALL_COHORTS = 'ENABLE_ACCESS_COMPETITION_IN_ALL_COHORTS';
export const DISABLE_ACCESS_COMPETITION_IN_ALL_COHORTS = 'DISABLE_ACCESS_COMPETITION_IN_ALL_COHORTS';

